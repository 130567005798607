import { template as template_0a213f92c63e46e4ac32089094aa737e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0a213f92c63e46e4ac32089094aa737e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
