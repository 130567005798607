import { template as template_fdcbfeb704bd4b24850301de5fe03b1f } from "@ember/template-compiler";
const FKText = template_fdcbfeb704bd4b24850301de5fe03b1f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
