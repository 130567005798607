import { template as template_e782b6ea962848dfa7726c9b6aa95e5d } from "@ember/template-compiler";
const FKControlMenuContainer = template_e782b6ea962848dfa7726c9b6aa95e5d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
