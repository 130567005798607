import { template as template_c52c57f3d4c444d3a201dfd0c49b12ec } from "@ember/template-compiler";
const FKLabel = template_c52c57f3d4c444d3a201dfd0c49b12ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
