import { template as template_2cde3c5185604d33ba203244a0a0abd1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2cde3c5185604d33ba203244a0a0abd1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
