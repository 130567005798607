import { template as template_db74d076d2f04f3197e2fdd484173774 } from "@ember/template-compiler";
const SidebarSectionMessage = template_db74d076d2f04f3197e2fdd484173774(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
